import { createFlickr } from "flickr-sdk"
const { flickr } = createFlickr("99a236cccb6c4e6ce2dedd7bc8115172");

class FlickrHelper {

    private galleryNameList:any[] = [];
    private albumPictureMap = new Map<string, any>();
    private user_id = '196021119@N04';
    private per_page = 100;

    static shuffle(array:any[]) {
        return array.sort(() => Math.random() - 0.5)
    }

    async getAlbumList() {
        if (this.galleryNameList.length !== 0) {
            return this.galleryNameList;
        }

        const res = await flickr("flickr.photosets.getList", {
            user_id: this.user_id,
        })
        
        function getAlbumCover(photoset: any) {
            var server_id = photoset.server;
            var cover_id = photoset.primary;
            var secret_id = photoset.secret;
            return "https://live.staticflickr.com/" + server_id + "/" + cover_id + "_" + secret_id + "_b.jpg";
        }

        var rngPhoto = FlickrHelper.shuffle(res.photosets.photoset)
        this.galleryNameList = rngPhoto.map(
            (photoset: any) => 
                ({
                    title: photoset.title._content,
                    id: photoset.id,
                    description: photoset.description._content,
                    src: getAlbumCover(photoset),
                    width:1000,
                    height:1000,
                })
        )
        return this.galleryNameList;
    }

    async getAlbumPictures(id: string, page:Number=1) {

            async function getAlbumPicturesFromAPI(user_id:string, id:string, page:Number, per_page:Number) {

                var rngPhoto = []
                if (id === "-1") {
                    const res = await flickr("flickr.people.getPublicPhotos", {
                        user_id: user_id,
                        extras: "url_o, url_m", 
                        per_page: String(per_page),
                        page: String(page)
                    })
                    rngPhoto = res.photos.photo
                }
                else {
                    const res = await flickr("flickr.photosets.getPhotos", {
                        user_id: user_id,
                        photoset_id: id,
                        extras: "url_o, url_m",
                        per_page: String(per_page),
                        page: String(page)
                    })
                    rngPhoto = res.photoset.photo
                }

                var newPageHigh = rngPhoto.map(
                    (photo: any) =>
                    ({
                        src: photo.url_o,
                        width: photo.width_o,
                        height: photo.height_o,
                        title: photo.title,
                        srcSet: [
                            { src: photo.url_o, width: photo.width_o, height: photo.height_o },
                            { src: photo.url_m, width: photo.width_m, height: photo.height_m }
                        ]
                    })
                )
                var newPageLow = rngPhoto.map(
                    (photo: any) =>
                    ({
                        src: photo.url_m, 
                        width: photo.width_m, 
                        height: photo.height_m,
                        title: photo.title,
                    })
                )
                return {newPageHigh, newPageLow, finished: false};
            }
            var album: any | undefined = this.albumPictureMap.get(id);
            if (album !== undefined) {
                if (album.finished) {
                    return album
                }
                page = album.newPageHigh.length/this.per_page + 1
                var newPage = await getAlbumPicturesFromAPI(this.user_id, id, page, this.per_page);
                if (newPage.newPageHigh.length < this.per_page) {
                    album.finished = true
                }
                album.newPageHigh = album.newPageHigh.concat(newPage.newPageHigh)
                album.newPageLow = album.newPageLow.concat(newPage.newPageLow)
                this.albumPictureMap.set(id, album);
                return album;
            }
            var newNewPage = await getAlbumPicturesFromAPI(this.user_id, id, page, this.per_page);
            if (newNewPage.newPageHigh.length < this.per_page) {
                newNewPage.finished = true
            }
            this.albumPictureMap.set(id, newNewPage);
            return newNewPage;
    }

}

export const flickrHelper = new FlickrHelper()