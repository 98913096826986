import { useEffect, useState } from 'react';
import './AlbumPage.css'
import PhotoAlbum from 'react-photo-album';
import { flickrHelper } from '../helpers/flickrHelper';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

function AlbumPage(props: any) {

    const [pictures, setPictures] = useState<any>({});
    const [index, setIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);

    const handleScroll = () => {
        var currY = window.scrollY + window.innerHeight;
        var maxY = document.body.scrollHeight;
        if (isLoading) {
            return
        }
        if (currY >= 0.9 * maxY) {
            getPictures()
        }
    }

    async function getPictures() {
        setIsLoading(true);
        try {
            setPictures(await flickrHelper.getAlbumPictures(props.album.id));
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [isLoading]);

    useEffect(() => {
        getPictures();
        window.scrollTo(0, 0);
    }, [props.album.id]);

    function renderPhotos() {
        if (pictures.newPageLow === undefined) {
            return null
        }
        return(
            <>
                <PhotoAlbum 
                    photos={pictures.newPageLow} 
                    layout={props.isMobile ? "rows" : "masonry"}
                    targetRowHeight={500}
                    onClick={({index}) => setIndex(index)}
                    renderContainer= {({containerProps, children, containerRef}) => (
                        <div
                            style={{
                                paddingTop: "2rem",
                                paddingRight: "5rem",
                                paddingLeft: "5rem",
                                paddingBottom: "2rem",
                            }}
                        >
                            <div ref={containerRef} {...containerProps}>
                                {children}
                            </div>
                        </div>
                    )}/>
                <Lightbox
                    slides={pictures.newPageHigh}
                    open={index >= 0}
                    close={() => setIndex(-1)}
                    plugins={[Zoom]}
                    index={index}
                />
            </>
        )
    }
    return (
        <div>
            <div className="Album">
                <div className="AlbumTitle">
                    <h1 className="display-1">{props.album.title}</h1>
                    <h2> <small className="text-muted">{props.album.description}</small></h2>
                </div>
            </div>
            {renderPhotos()}
        </div>
    )
}

export default AlbumPage;