import React from 'react';
import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { flickrHelper } from './helpers/flickrHelper';
import HomePage from './pages/HomePage'
import AlbumHomePage from './pages/AlbumHomePage'
import AlbumPage from './pages/AlbumPage';
import NavBar from './components/NavBar';
function App() {
    const [albums, setAlbums] = useState<any[]>([]);
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
    }, [])
    
    useEffect(() => {
        async function getAlbums() {
            setAlbums(await flickrHelper.getAlbumList());
        }
        getAlbums();
    }, [albums]);

    const isMobile = width <= 768;

    return (
    <>
        <div className="pictures">
        <NavBar albumList={albums} isMobile={isMobile}/>
            <div className="mainBody">
                    <Routes>
                        <Route path="/" element={<HomePage isMobile={isMobile}/>} />
                        <Route path="/albums" element={<AlbumHomePage albumList={albums} isMobile={isMobile}/>} />
                        {albums.map((album) => 
                            (<Route key={album.id} path={"/albums/" + album.title} element={<AlbumPage album={album} isMobile={isMobile}/>}/>))}
                    </Routes>
            </div>
        </div>

    </>
    )
}

export default App;