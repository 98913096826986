import { NavLink } from "react-router-dom";
import './AlbumHomePage.css'
import PhotoAlbum from "react-photo-album";
import { CImage, CListGroup, CListGroupItem } from "@coreui/react";

function AlbumHomePage(props: any) {

    function renderAlbumName(isMobile: any){
        if (isMobile) {
            return (
                <center>
                    <CListGroup flush>
                        {props.albumList.map((photo: any) => (
                            <CListGroupItem component="a" href={"#/albums/" + photo.title}>
                                <br></br>
                                <CImage fluid src={photo.src}/>
                                {photo.title}
                            </CListGroupItem>
                        ))}
                    </CListGroup>
                </center>
            )
        }
        return (
            <PhotoAlbum 
            photos={props.albumList} 
            layout="masonry"
            padding={20}
            spacing={20}
            renderPhoto= {({ photo, imageProps: {style, ...restImageProps}, renderDefaultPhoto }) => (
                <NavLink to={"/albums/" + photo.title}>
                    <center>
                        <div className="album_cover_wrapper">
                            {renderDefaultPhoto({wrapped: true})}
                            <div className="album_name_layer">
                                <p className="album_name">{photo.title}</p>
                            </div>
                        </div>
                        <br></br>
                    </center>
                </NavLink>
            )}
            renderContainer= {({containerProps, children, containerRef}) => (
                <div
                    style={{
                        paddingTop: "2rem",
                        paddingRight: "5rem",
                        paddingLeft: "5rem",
                        paddingBottom: "2rem",
                    }}
                >
                    <div ref={containerRef} {...containerProps}>
                        {children}
                    </div>
                </div>
            )}
            />
        )
    }
    return (
    <>
        <center>
            <h1 className="display-1"> Albums </h1>
            <h2> <small className="text-muted">Pictures I've taken grouped in various ways</small></h2>
        </center>
        <div className="albumGrid">
            {renderAlbumName(props.isMobile)}
        </div>
    </>
    )
}


export default AlbumHomePage;