import React, { useEffect, useState } from 'react';
import './NavBar.css'
import { CCollapse, CContainer, CDropdown, CDropdownDivider, CDropdownItem, CDropdownMenu, CDropdownToggle, CNavItem, CNavLink, CNavbar, CNavbarBrand, CNavbarNav, CNavbarToggler } from '@coreui/react'

function NavBar(props: any) {

    const [visible, setVisible] = useState(false);
    const [albumList, setAlbumList] = useState<any[]>([]);



    useEffect(() => {
        setAlbumList([...props.albumList].sort((a: any, b: any) => (a.title < b.title ? -1 : 1)))
    }, [props.albumList]);


    function renderAlbums(isMobile: any) {
        if (isMobile) {
            return (                        
                <CNavItem>
                    <CNavLink href="#/albums" active>
                        Albums
                    </CNavLink>
                </CNavItem>
            )
        }
        return (
            <CDropdown variant="nav-item" popper={false}>
                <CDropdownToggle> Albums </CDropdownToggle>
                <CDropdownMenu>
                    <CDropdownItem href="#/albums">All Albums</CDropdownItem>
                    <CDropdownDivider/>
                    {albumList.map((album: any) => (
                        <CDropdownItem href={"#/albums/" + album.title}>
                                {album.title}<br/>
                        </CDropdownItem>
                    ))}
                </CDropdownMenu>
            </CDropdown>            
        )
    }

    return (
        <CNavbar expand="lg" colorScheme='light' className="bg-light" placement="sticky-top"> 
            <CContainer fluid>
                <CNavbarBrand href="#">Banana Daemon</CNavbarBrand>
                <CNavbarToggler onClick={() => setVisible(!visible)} />
                <CCollapse className="navbar-collapse" visible={visible}>
                    <CNavbarNav>
                        {renderAlbums(props.isMobile)}
                    </CNavbarNav>
                </CCollapse>
            </CContainer>
        </CNavbar>
        
    )
}

export default NavBar;